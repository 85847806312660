<template>
  <b-form-group class="barcode-input-container">
    <multiselect
      id="products"
      track-by="id"
      label="name"
      v-model="item"
      class="mt-3 with-border w-100"
      placeholder="Buscar por kit e/ou produto."
      :options="itemsOptions"
      :searchable="true"
      :allow-empty="false"
      :option-height="40"
      :showLabels="false"
      :loading="false"
      @search-change="debounceSearchItems"
      @select="select"
    >
      <template slot="caret">
        <div class="barcode-container">
          <Barcode class="barcode"/>
        </div>
      </template>

      <template slot="singleLabel" slot-scope="props">
        <span>{{ props.option.name }}</span>
      </template>

      <template slot="option" slot-scope="props">
        <span>{{ props.option.name }}</span>
      </template>

      <template slot="noOptions">
        <div>Digite para pesquisar.</div>
      </template>
      
      <template slot="noResult">
        <div>Nenhum produto ou kit encontrado...</div>
      </template>
    </multiselect>
  </b-form-group>
</template>

<script>
import debounce from 'lodash/debounce'
import { getCurrentClinic } from '@/utils/localStorageManager'

export default {
  components: {
    Barcode: () => import('@/assets/icons/barcode.svg'),
  },
  data: () => ({
    item: null,
    itemsOptions: [],
    debounceSearchItems: Function,
    clinic: getCurrentClinic(),
  }),

  async created() {
    this.debounceSearchItems = debounce(this.SearchItems, 1000);
  },

  methods: {
    select(item) {
      this.$emit('select', item)
      this.item = null
    },
    async SearchItems (query) {
      if (!query) return

      try {
        const products = await this.api.getWarehouseEntryTransactions(this.clinic.id, 1, query, null, null, null, null, query)
        const kits = await this.api.getKits(this.clinic.id, { query:query }, 1)

        const kitsOptions = kits.data.data.map(option => {
          return {
            id: option?.id,
            name: `${option?.code} - ${option?.template?.name}`,
            type: 'kit',
          }
        })

        const productsOptions = products.data.data.map(option => {
          return {
            id: option?.id,
            name: `${option?.barcode} - ${option?.product?.name}`,
            type: 'warehouse_product_transactions',
          }
        })

        this.itemsOptions = [...kitsOptions, ...productsOptions]
      } catch (err) {
        this.$toast.error(err.message)
      } 
    },
  }
}
</script>

<style lang="scss" scoped>
.barcode-input-container {
  width: 100%;
  border-top: 1px solid var(--neutral-200);

  .barcode-container {
    width: 24px;
    height: 24px;
    padding: 0;
    margin-top: 3px;
    right: 16px;
    position: absolute;
  }
  .barcode {

    position: relative;
    transform: scale(0.75);
    margin-left: -10px;
    
  }
}
</style>